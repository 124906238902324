import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { GET_BARE_SENT_EMAILS } from "../../../../utils/GraphQL/queries";
import "./sentEmailsPage.css";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import { useMediaQuery, CircularProgress } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import SentEmailModal from "../../adminComponents/SentEmailModal/SentEmailModal";

const SentEmailsPage = () => {
  const [sentEmailId, setSentEmailId] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [emailModalOpen, setEmailModalOpen] = useState(false);
  const [openSearch, setOpenSearch] = useState(false);
  const [emailsLoading, setEmailsLoading] = useState(true);
  const [getSentEmails] = useLazyQuery(GET_BARE_SENT_EMAILS);
  const smaller600 = useMediaQuery("(max-width:600px)");
  const [emails, setEmails] = useState([]);
  const [emailOriginal, setEmailOriginal] = useState([]);
  const [callCompleted, setCallCompleted] = useState(true);
  const [pageControl, setPageControl] = useState({
    totalDoc: 0,
    pageNum: 1,
    highestPage: 1,
    loading: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // getSentEmailsFunc();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (emailOriginal) {
      setEmails([...emailOriginal]);
    }
    // eslint-disable-next-line
  }, [emailOriginal]);

  useEffect(() => {
    if (!openSearch && searchInput?.length === 0) {
      setEmails([...emailOriginal]);
    }
    // eslint-disable-next-line
  }, [openSearch, searchInput]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (emailModalOpen === true) {
      body.style.overflow = "hidden";
    }
    if (emailModalOpen === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailModalOpen]);

  useEffect(() => {
    let intervalId = "";

    if (openSearch === false) {
      setCallCompleted(true);
      getSentEmailsFunc();
    }

    if (searchInput?.length > 0) {
      intervalId = setTimeout(() => {
        if (pageControl?.pageNum === 1) {
          getSentEmailsFunc();
        } else {
          setPageControl((prev) => ({
            ...prev,
            pageNum: 1,
            highestPage: 1,
          }));
        }
      }, 1500);
    }

    return () => clearTimeout(intervalId);
    // eslint-disable-next-line
  }, [openSearch, searchInput]);

  useEffect(() => {
    getSentEmailsFunc();
    // eslint-disable-next-line
  }, [pageControl?.pageNum, pageControl?.limit]);

  const getSentEmailsFunc = async () => {
    const { data } = await getSentEmails({
      fetchPolicy: "network-only",
      variables: {
        page: pageControl?.pageNum?.toString(),
        searching: searchInput,
      },
    });

    if (data?.getBareSentEmails) {
      const totalCount = parseInt(data?.getBareSentEmails?.totalDoc);

      // Set limit on query to 50
      setEmailOriginal([...data?.getBareSentEmails?.emails]);
      setPageControl((prev) => ({
        ...prev,
        totalDoc: totalCount,
        highestPage: Math.floor(totalCount / 50) + 1,
        loading: false,
      }));
    }

    setEmailsLoading(false);
    setCallCompleted(true);
  };

  const openEmail = (id) => {
    setSentEmailId(id);
    setEmailModalOpen(true);
  };

  const closeEmail = (event) => {
    setSentEmailId("");
    setEmailModalOpen(false);
    getSentEmailsFunc();
  };

  const handlePage = (page) => {
    let newPage = 1;

    if (page === "up" && pageControl?.pageNum < pageControl?.highestPage) {
      newPage = pageControl?.pageNum + 1;
    }

    if (page === "up" && pageControl?.pageNum >= pageControl?.highestPage) {
      newPage = pageControl?.highestPage;
    }

    if (page === "down" && pageControl?.pageNum > 1) {
      newPage = pageControl?.pageNum - 1;
    }

    if (page === "down" && pageControl?.pageNum <= 1) {
      newPage = 1;
    }

    setPageControl((prev) => ({
      ...prev,
      pageNum: newPage,
    }));
  };

  const handleSearchInput = (event) => {
    const value = event.target.value;
    setCallCompleted(false);
    setSearchInput(value);
    // const regex = RegExp(value.trim(), "i");

    // const foundEmails = emailOriginal?.slice()?.filter((emlObj) => {
    //   if (
    //     regex.test(emlObj?.from) ||
    //     regex.test(emlObj?.emailSubject) ||
    //     regex.test(emlObj?.to)
    //   ) {
    //     return true;
    //   }
    //   return false;
    // });

    // setEmails([...foundEmails]);
  };

  const loadingHolders = () => {
    return Array.from({ length: 15 }).map((_, index) => (
      <div key={index + Date.now()} className="email-item-wrapper">
        <div className="email-item-loading">
          <div>
            <div>
              <span className="email-item loading">From:</span>
            </div>
          </div>
          <div>
            <span className="email-item loading">Subject:</span>
          </div>
          <div className="email-has-attach loading">att: 0</div>
        </div>
      </div>
    ));
  };

  return (
    <div className="sent-email-page">
      {emailModalOpen && (
        <SentEmailModal closeEmail={closeEmail} sentId={sentEmailId} />
      )}
      <div
        className={
          emailModalOpen
            ? "sent-email-page-container"
            : "sent-email-page-container sent-email-page-filter"
        }
      >
        <h2>Viewing Email History</h2>
        <div className="sent-email-actions-button-box">
          <button
            className={
              smaller600
                ? "sent-email-page-action-img-button"
                : "sent-email-archive-button"
            }
            onClick={() => {
              if (openSearch) {
                setSearchInput("");
              }
              setOpenSearch((prev) => !prev);
            }}
          >
            {smaller600 ? (
              <SearchIcon titleAccess="Search Emails" />
            ) : openSearch ? (
              "Close Search"
            ) : (
              "Search Emails"
            )}
          </button>
        </div>
        {openSearch && (
          <div className="sent-email-page-search-box">
            <div className="sent-email-page-search-input">
              <CssTextFieldStandard
                sx={{ width: "100%", my: 0.5 }}
                type="text"
                label={`Search`}
                name="to"
                inputMode="text"
                autoFocus
                value={searchInput}
                onChange={handleSearchInput}
                variant="standard"
                InputProps={{
                  autoComplete: "off",
                  endAdornment: !callCompleted && searchInput?.length > 0 && (
                    <div
                      style={{
                        color: "#ffffff",
                        fontSize: "inherit",
                      }}
                    >
                      <CircularProgress color="inherit" size={20} />
                    </div>
                  ),
                }}
              />
            </div>
          </div>
        )}
        {pageControl?.totalDoc > 0 && (
          <div className="email-page-page-box">
            <span style={{ margin: "0px 5px" }}>
              {`${pageControl?.totalDoc} ${
                pageControl?.totalDoc > 1 ? "Emails" : "Email"
              } - Page ${pageControl?.pageNum} of ${pageControl?.highestPage}`}
              {/* {!openSearch && (
                <> */}
              {/* - Page {pageControl?.pageNum} of {pageControl?.highestPage} */}
              {/* </>
              )} */}
            </span>
            {/* {!openSearch && (
              <> */}
            {pageControl?.pageNum !== 1 && (
              <div className="email-page-page-control-button-box">
                <button
                  disabled={pageControl?.loading}
                  className="email-page-page-control-button"
                  onClick={() => {
                    setPageControl((prev) => ({ ...prev, loading: true }));
                    handlePage("down");
                  }}
                >
                  <strong>-</strong>
                </button>
              </div>
            )}
            {pageControl?.pageNum !== pageControl?.highestPage && (
              <div className="email-page-page-control-button-box">
                <button
                  disabled={pageControl?.loading}
                  className="email-page-page-control-button"
                  onClick={() => {
                    setPageControl((prev) => ({ ...prev, loading: true }));
                    handlePage("up");
                  }}
                >
                  <strong>+</strong>
                </button>
              </div>
            )}
            {/* </>
            )} */}
          </div>
        )}
        {emailsLoading && loadingHolders()}
        {emails?.length > 0 ? (
          emails?.map((email, index) => {
            let date = undefined;
            const getNum = parseInt(email?.dateCreated);
            if (getNum > 0) {
              date = BAGroupTimeFormat(getNum, true);
            }

            return (
              <div
                className="sent-email-item-wrapper"
                key={"emails-number:" + email?.id + index}
              >
                <div className="sent-email-item">
                  <div className="sent-email-open-btns">
                    <button
                      className="sent-email-info-section"
                      onClick={() => {
                        openEmail(email?.id);
                      }}
                    >
                      <div className="text-overflow-ellipsis">
                        <span className="sent-email-item-grn ">To:</span>{" "}
                        {email?.to}
                        <div className="text-overflow-ellipsis">
                          <span className="sent-email-item-grn">From:</span>{" "}
                          {email?.from}
                        </div>
                      </div>
                      <div className="sent-email-item-small-date">
                        <span className="sent-email-item-grn">Date: </span>{" "}
                        {date || "Missing Date"}
                      </div>
                    </button>
                    <button
                      name={email?.id}
                      className="sent-email-info-section"
                      onClick={() => {
                        openEmail(email?.id);
                      }}
                    >
                      <div className="text-overflow-ellipsis">
                        <span className="sent-email-item-grn">Subject:</span>{" "}
                        {email?.emailSubject}
                      </div>
                    </button>
                  </div>
                  <div className="sent-email-actions-box">
                    <div
                      className={
                        email?.attachments > 0
                          ? "sent-email-has-attach att-error"
                          : "sent-email-has-attach"
                      }
                    >
                      att: {email?.attachments}
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h2 className="page-header">No Emails To Display</h2>
        )}
      </div>
    </div>
  );
};

export default SentEmailsPage;
