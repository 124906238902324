import React, { useEffect, useState } from "react";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import "./adminPictureUpload.css";
import { useMutation, useLazyQuery } from "@apollo/client";
import { NEW_CLIENT_PHOTO } from "../../../../utils/GraphQL/mutations";
import { QUERY_CLIENT_PHOTOS } from "../../../../utils/GraphQL/queries";
import BdrSpinner from "../../../../components/BdrSpinner/BdrSpinner";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";

import resizeImage from "../../../../utils/formatters/resizeImage";

const PictureUploadAdmin = ({
  imgDate,
  changeLoading,
  changePhotoIds,
  BoaProp,
  clearState,
  clearStateTriggerCB,
}) => {
  const [files, setFiles] = useState();
  const [imgDisplayLoaded, setImgDisplayLoaded] = useState(Boolean);
  const [uploadedImageTempUrl, setUploadedImageTempUrl] = useState([]);
  const [savePhoto, { loading: imgSaving }] = useMutation(NEW_CLIENT_PHOTO);
  const [loadPhoto, { data: loadedImg, loading: imgLoading }] =
    useLazyQuery(QUERY_CLIENT_PHOTOS, {fetchPolicy: "network-only"});

  // Clears state for multiple uploads
  const resetState = () => {
    clearTempUrls()
    setFiles()
  };

  const clearTempUrls = () => {
    setUploadedImageTempUrl([]);
  };

  const handleUploadSubmit = async () => {
    if (files?.length === 0) {
      return;
    }

    setImgDisplayLoaded(false);
    await changeLoading(true);

    for await (const file of files) {
      const date = Date.now();
      const resizedImg = await resizeImage(file);

      if ((await resizedImg.length) > 0) {
        const photoData = await savePhoto({
          variables: {
            imageData: resizedImg,
            beforeOrAfter: BoaProp,
            dateEntered: imgDate || date.toString(),
          },
        });

        if (photoData.data) {
          changePhotoIds(photoData.data.storeImage._id);
        }
      }
    }

    changeLoading(false);
    return;
  };

  const handleFileChange = (event) => {
    clearTempUrls();
    if (event.target.files.length === 0) {
    }

    if (event.target.files) {
      setFiles([...event.target.files]);

      return;
    }
  };

  const handleIdChange = async (event) => {
    const value = event.target.value;

    clearTempUrls();

    if (value.length >= 24) {
      setImgDisplayLoaded(true);
      await loadPhoto({ variables: { pictureIdArr: [value] } });
      changePhotoIds(value);

      return;
    }

    setImgDisplayLoaded(null);
    changePhotoIds("");
  };

  const createTemps = async () => {
    const b64toBlob = (
      b64Data,
      contentType = "image/jpeg",
      sliceSize = 512
    ) => {
      const splitData = b64Data.split(",")[1];
      const byteCharacters = atob(splitData);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    };

    if (files) {
      for (const file of files) {
        const newSize = await resizeImage(file);
        const blob = b64toBlob(newSize);
        const url = URL.createObjectURL(blob);
        const name = file.name;

        setUploadedImageTempUrl((uploadedImageTempUrl) => [
          ...uploadedImageTempUrl,
          { url, name },
        ]);
      }

      handleUploadSubmit();

      return;
    }
  };

  useEffect(() => {
    createTemps();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    if (clearState) {
      resetState();
      clearStateTriggerCB();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clearState]);

  return (
    <div className="uploaded-container">
      <div className="id-input">
        <CssTextFieldStandard
          sx={{ width: "95%", my: 1 }}
          fontSize="8px"
          type="text"
          label={`Enter photo Id`}
          inputProps={{ inputMode: "text" }}
          name="photoId"
          onChange={handleIdChange}
          variant="standard"
        />
      </div>
      <h5>Or</h5>
      <label className="custom-file-upload">
        <input
          onChange={handleFileChange}
          type="file"
          accept="image/*"
          name="clientImageUpload"
        />
        <FileUploadIcon /> Select File
      </label>
      <div className="uploaded-box relative">
        {imgDisplayLoaded ? (
          <>
            <div className={imgLoading ? "over-loader" : "none"}>
              <BdrSpinner message={"Loading..."} />
            </div>
            {loadedImg && (
              <img
                className="admin-uploaded-pic"
                src={loadedImg.getImageById[0].imageData}
                alt="Client asset loaded"
              />
            )}
          </>
        ) : (
          <>
            <div className={imgSaving ? "over-loader" : "none"}>
              <BdrSpinner message={"Saving..."} />
            </div>
            {uploadedImageTempUrl &&
              uploadedImageTempUrl.map((file) => {
                return (
                  <img
                    className="admin-uploaded-pic"
                    key={file.name + Date.now()}
                    src={file.url}
                    alt="Client upload"
                  />
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};
export default PictureUploadAdmin;
