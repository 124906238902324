import React, { useEffect, useState } from "react";
import { UPDATE_USER_INFO } from "../../../../utils/GraphQL/mutations";
import "./userInfoModal.css";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import phoneNumberFormat from "../../../../utils/formatters/phoneNumberFormat";
import UpdateUserInfoTest from "../../../../utils/formValidators/userUpdateInfoCheck";
import { useMediaQuery } from "@mui/material";
import { useMutation } from "@apollo/client";
import Auth from "../../../../utils/auth";
import capitalizeFirstLetters from "../../../../utils/formatters/capitalizeFirstLetters";

const UserInfoModal = ({ user, updateModalHandler }) => {
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [numbers, setNumbers] = useState();
  const [saving, setSaving] = useState(false);
  const [updatedInformation, setUpdatedInformation] = useState({});
  const [invalidErrors, setInvalidErrors] = useState();
  const [updateInfoMutation] = useMutation(UPDATE_USER_INFO);
  const [initLoginObj, setInitLoginObj] = useState({
    id: "",
    city: "",
    email: "",
    firstName: "",
    lastName: "",
    initLogin: true,
    phoneNumberIds: [],
    state: "",
    streetAddress: "",
    zipCode: "",
    prevPass: "",
    newPass: "",
  });

  // form validation class
  const updateTest = new UpdateUserInfoTest(updatedInformation);

  useEffect(() => {
    if (user !== undefined) {
      console.log(user);
      const formattedNumbers =
        user?.phoneNumberIds.length > 0
          ? user?.phoneNumberIds.map((num, index) => {
              return {
                ...num,
                phoneNumber: phoneNumberFormat(num.phoneNumber),
              };
            })
          : [
              {
                _id: "newNum",
                phoneNumber: phoneNumberFormat("0000000000"),
                numberType: "Cell",
              },
            ];

      setNumbers(formattedNumbers);

      setInitLoginObj({
        id: user?._id,
        city: user?.city || "",
        email: user?.email || "",
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        initLogin: user?.initLogin,
        phoneNumberIds: formattedNumbers || [],
        state: user?.state || "",
        streetAddress: user?.streetAddress || "",
        zipCode: user?.zipCode || "",
        prevPass: "",
        newPass: "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    setUpdatedInformation({ ...initLoginObj, phoneNumberIds: numbers });
  }, [initLoginObj, numbers]);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = event.target.value;

    if (
      name === "firstName" ||
      name === "city" ||
      name === "state" ||
      name === "streetAddress" ||
      name === "lastName"
    ) {
      value = capitalizeFirstLetters(value);

      setInitLoginObj((prev) => {
        return { ...prev, [name]: value };
      });

      return;
    }

    setInitLoginObj((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handlePhoneNumber = (event, index) => {
    const id = event.target.name;
    const filteredNumber = event.target.value.replace(/[^0-9]/gi, "");

    const filterUpdate = updatedInformation.phoneNumberIds.map((number) => {
      if (id === number._id) {
        return { ...number, phoneNumber: phoneNumberFormat(filteredNumber) };
      }
      return number;
    });

    setNumbers(filterUpdate);
  };

  const autoEmailAdd = (event) => {
    const atDomain = event.target.innerText;
    const email = initLoginObj?.email.split("@")[0];

    setInitLoginObj((prev) => {
      return { ...prev, email: `${email}${atDomain}` };
    });
  };

  const submitUpdateInfo = async () => {
    const test = await updateTest.updateValid();
    if (test === true) {
      setInvalidErrors();
      const stripPhoneNumArr = updatedInformation.phoneNumberIds.map((num) => {
        return {
          _id: num._id,
          phoneNumber: num.phoneNumber,
          numberType: "Cell",
          title: num.title,
        };
      });

      const { data: updatedUser } = await updateInfoMutation({
        variables: {
          updateUserId: updatedInformation.id,
          firstName: updatedInformation.firstName,
          lastName: updatedInformation.lastName,
          email: updatedInformation.email,
          phoneNumberIds: stripPhoneNumArr,
          streetAddress: updatedInformation.streetAddress,
          city: updatedInformation.city,
          state: updatedInformation.state,
          zipCode: updatedInformation.zipCode,
          newPass: updatedInformation.newPass,
          prevPass: updatedInformation.prevPass,
          initLogin: updatedInformation.initLogin,
        },
      });

      setSaving(false);
      Auth.resetToken(updatedUser?.updateUser.token);
      return;
    }

    setInvalidErrors(test);
    setSaving(false);
    return;
  };

  return (
    <>
      <div
        className="user-info-modal-container"
        onClick={(event) => {
          event.stopPropagation();
          if (updatedInformation?.initLogin === false) {
            updateModalHandler(false);
          }
        }}
      >
        {saving && <AdminBDRSpinner message={"Saving..."} />}
        <div
          className="user-info-modal-content"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          {initLoginObj.initLogin === true ? (
            <h2 className="user-info-sec-hdr">Please Enter Your Information</h2>
          ) : (
            <div className="user-info-modal-close">
              <h2 className="user-info-sec-hdr">Update Information</h2>
              <button
                onClick={(event) => {
                  event.stopPropagation();
                  if (updatedInformation?.initLogin === false) {
                    updateModalHandler(false);
                  }
                }}
                className="user-info-autofill-email"
              >
                Close
              </button>
            </div>
          )}
          <h4 className="user-info-sec-hdr">Name: </h4>
          <div className="user-info-modal-sec">
            <CssTextFieldStandard
              sx={{ width: "45%", my: 1 }}
              type="text"
              label={`First Name`}
              name="firstName"
              inputMode="text"
              value={initLoginObj.firstName}
              onChange={handleChange}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "45%", my: 1 }}
              type="text"
              label={`Last Name`}
              name="lastName"
              inputMode="text"
              value={initLoginObj.lastName}
              onChange={handleChange}
              variant="standard"
            />
          </div>
          <h4 className="user-info-sec-hdr">Contact: </h4>
          <div className="user-info-modal-sec">
            <CssTextFieldStandard
              sx={{ width: "100%", my: 1 }}
              type="text"
              label={`Email`}
              inputMode="text"
              name="email"
              value={initLoginObj.email}
              onChange={handleChange}
              variant="standard"
            />
          </div>
          <div className="user-info-modal-sec emails">
            <button onClick={autoEmailAdd} className="user-info-autofill-email">
              @gmail.com
            </button>
            <button onClick={autoEmailAdd} className="user-info-autofill-email">
              @yahoo.com
            </button>
            <button onClick={autoEmailAdd} className="user-info-autofill-email">
              @icloud.com
            </button>
          </div>
          <div className="user-info-modal-sec">
            {initLoginObj?.phoneNumberIds?.map((phoneNum, index) => {
              return (
                <CssTextFieldStandard
                  key={phoneNum?.phoneNumber + index}
                  sx={{ width: "40%", my: 1 }}
                  type="tel"
                  label={`Phone Number`}
                  inputProps={{ maxLength: 12, inputMode: "numeric" }}
                  name={phoneNum?._id}
                  value={numbers[index].phoneNumber}
                  onChange={(event) => {
                    handlePhoneNumber(event, index);
                  }}
                  variant="standard"
                />
              );
            })}
          </div>
          <h4 className="user-info-sec-hdr">Address: </h4>
          <div className="user-info-modal-sec">
            <CssTextFieldStandard
              sx={{ width: "100%", my: 1 }}
              type="text"
              label={`Street Address`}
              inputMode="text"
              name="streetAddress"
              value={initLoginObj.streetAddress}
              onChange={handleChange}
              variant="standard"
            />
          </div>
          <div className="user-info-modal-sec">
            <CssTextFieldStandard
              sx={{ width: "50%", my: 1 }}
              type="text"
              label={`City`}
              inputMode="text"
              name="city"
              value={initLoginObj.city}
              onChange={handleChange}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "15%", my: 1 }}
              type="text"
              label={`State`}
              inputMode="text"
              name="state"
              value={initLoginObj.state}
              onChange={handleChange}
              variant="standard"
            />
            <CssTextFieldStandard
              sx={{ width: "25%", my: 1 }}
              type="text"
              label={`Zip Code`}
              inputMode="text"
              name="zipCode"
              value={initLoginObj.zipCode}
              onChange={handleChange}
              variant="standard"
            />
          </div>
          <h4 className="user-info-sec-hdr">Password: </h4>
          <span>Must be longer then 8 characters.</span>
          <span>
            And include at least one uppercase, one lowercase and one number.
          </span>
          {initLoginObj.initLogin === false && (
            <span>
              To update password, please enter your previous and new password.
            </span>
          )}
          <div className="user-info-modal-sec">
            {initLoginObj.initLogin === false && (
              <CssTextFieldStandard
                sx={
                  smaller900 ? { width: "75%", my: 1 } : { width: "35%", my: 1 }
                }
                type="password"
                label={`Previous Password`}
                inputMode="text"
                name="prevPass"
                value={initLoginObj?.prevPass}
                onChange={handleChange}
                variant="standard"
              />
            )}
            <CssTextFieldStandard
              sx={
                smaller900 ? { width: "75%", my: 1 } : { width: "35%", my: 1 }
              }
              type="password"
              label={`Enter New Password`}
              inputMode="text"
              name="newPass"
              value={initLoginObj?.newPass}
              onChange={handleChange}
              variant="standard"
            />
          </div>
          {invalidErrors && (
            <div className="user-info-modal-sec center">
              <ul>
                {invalidErrors.map((error, index) => {
                  return <li key={error + index}>{error}</li>;
                })}
              </ul>
            </div>
          )}
          <div className="user-info-modal-sec center">
            <button
              className="update-modal-item-btn"
              onClick={() => {
                setSaving(true);
                submitUpdateInfo();
              }}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserInfoModal;
