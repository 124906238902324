import React, { useState, useRef, useEffect } from "react";
import { DEACTIVATE_USER } from "../../../../utils/GraphQL/mutations";
import { Snackbar } from "@mui/material";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import "./listAllUsers.css";
import { useMutation, useLazyQuery } from "@apollo/client";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_ALL_USERS } from "../../../../utils/GraphQL/queries";
import AdminBDRSpinner from "../AdminBDRSpinner/AdminBDRSpinner";
import { priceStrFormatter } from "../../../../utils/formatters/priceStrFormatter";
import WageModal from "./WageModal";

const ListAllUsers = () => {
  const listUsersScrollRef = useRef();
  const smaller900 = useMediaQuery("(max-width:900px)");
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [openWageModal, setOpenWageModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);
  const [deactivateUserProf] = useMutation(DEACTIVATE_USER);
  const [fetchUsers] = useLazyQuery(GET_ALL_USERS, {
    fetchPolicy: "network-only",
  });
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    if (!barCollapsed && usersData?.length <= 0) {
      setUsersLoading(true);
      getUsersInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [barCollapsed]);

  useEffect(() => {
    const body = document.querySelector("body");

    if (openWageModal === true) {
      body.style.overflow = "hidden";
    }
    if (openWageModal === false) {
      body.style.overflow = "auto";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openWageModal]);

  const getUsersInfo = async () => {
    const { data } = await fetchUsers();
    // console.log(data?.getAllUsers);
    if (data?.getAllUsers?.length > 0) {
      setUsersData([...data?.getAllUsers]);
      setUsersLoading(false);
      return;
    }

    setUsersLoading(false);
    return;
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });
  };

  const deactivateUser = async (userId) => {
    const deactivated = await deactivateUserProf({
      variables: { idDelete: userId },
      refetchQueries: [{ query: GET_ALL_USERS }],
    });

    if (deactivated) {
      setOpenSnack({
        visible: true,
        message: "Account Deactivated",
        duration: 3000,
      });

      return;
    }

    setOpenSnack({
      visible: false,
      message: "",
      duration: 2000,
    });

    return;
  };

  const closeWageModal = (event) => {
    if (event) {
      event.stopPropagation();
    }

    setOpenWageModal(false);
    setSelectedUser({});
  };

  const updateUserWageCB = ({ userId, wage }) => {
    setUsersData((prev) => {
      const updatedArr = prev?.map((user) => {
        if (userId !== user._id) {
          return user;
        }

        return { ...user, hourlyWage: wage };
      });

      return [...updatedArr];
    });
  };

  return (
    <div
      ref={listUsersScrollRef}
      id="list-user-height-marker"
      className="list-user-container"
    >
      {" "}
      {usersLoading && <AdminBDRSpinner message={"Loading..."} />}
      {openWageModal && (
        <WageModal
          selectedUser={selectedUser}
          closeWageModal={closeWageModal}
          handleSnack={handleSnack}
          updateUserWageCB={updateUserWageCB}
        />
      )}
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <button
        onClick={() => {
          setTimeout(() => {
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = listUsersScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              listUsersScrollRef.current.scrollIntoView({ block: "start" });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>List All Users</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        <div className="list-user-content">
          {usersData &&
            usersData?.map((user, index) => {
              const boxClass =
                selectedUser?._id === user?._id
                  ? "user-list-box selected-listed-user"
                  : "user-list-box";
              return (
                <div className={boxClass} key={user?.firstName + index}>
                  <div className="user-name-box">
                    <h3>
                      {user?.firstName} {user?.lastName}
                    </h3>
                    <h5>Perm: {user?.permission}</h5>
                    <h5 className="user-email-word-break">
                      Email: {user?.email}
                    </h5>
                    {user?.hourlyWage !== "0" && (
                      <h5 className="user-email-word-break">
                        Wage: {priceStrFormatter(user?.hourlyWage)}
                      </h5>
                    )}
                  </div>
                  <div className="list-users-actions-box">
                    <button
                      className="user-deactivate-btn"
                      onClick={() => {
                        setSelectedUser(user);
                        setOpenWageModal(true);
                      }}
                    >
                      Edit Wage
                    </button>
                    <button
                      className="user-deactivate-btn"
                      onClick={() => {
                        handleSnack(
                          <div className="user-deactivate-confirm">
                            <span className="user-deactivate-text-confirm">
                              deactivate {user?.firstName}'s Profile?
                            </span>
                            <button
                              className="user-deactivate-btn"
                              onClick={() => {
                                handleSnack(
                                  <div className="user-deactivate-confirm">
                                    <span className="user-deactivate-text-confirm">
                                      Are you sure?
                                    </span>
                                    <button
                                      className="user-deactivate-btn"
                                      onClick={() => {
                                        deactivateUser(user._id);
                                      }}
                                    >
                                      <DeleteIcon
                                        sx={{
                                          fontSize: "20px",
                                          color: "#b40000",
                                        }}
                                      />
                                      (Deactivate)
                                    </button>
                                  </div>,
                                  10000
                                );
                              }}
                            >
                              <DeleteIcon
                                sx={{ fontSize: "20px", color: "#b40000" }}
                              />
                              (Deactivate)
                            </button>
                          </div>,
                          10000
                        );
                      }}
                    >
                      <DeleteIcon sx={{ fontSize: "20px", color: "#b40000" }} />
                      (Deactivate)
                    </button>
                  </div>
                  {/* <div>
                    <CssTextFieldStandard
                      sx={{ width: "95%", my: 1 }}
                      type="text"
                      label="Hourly Wage"
                      name="wage"
                      onChange={handleChange}
                      onBlur={handleChange}
                      value={newClientForm?.firstName}
                      variant="standard"
                    />
                  </div> */}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ListAllUsers;
