import React, { useEffect, useState } from "react";
import "./listAllUsers.css";
import { CssTextFieldStandard } from "../../../../components/CssTextFields/CssTextFieldStandard";
import {
  priceStrFormatter,
  removePriceStrFormatter,
} from "../../../../utils/formatters/priceStrFormatter";
import { useMutation } from "@apollo/client";
import { UPDATE_USER_WAGE } from "../../../../utils/GraphQL/mutations";

const clearObj = {
  userId: "",
  wage: "",
};

const WageModal = ({
  selectedUser,
  closeWageModal,
  handleSnack,
  updateUserWageCB,
}) => {
  const [updateObj, setUpdateObj] = useState({ ...clearObj });
  const [updateWage] = useMutation(UPDATE_USER_WAGE);

  useEffect(() => {
    if (selectedUser) {
      setUpdateObj({
        userId: selectedUser?._id,
        wage: selectedUser?.hourlyWage,
      });
    }

    if (!selectedUser) {
      setUpdateObj({ ...clearObj });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedUser]);

  const handleChange = (event) => {
    const name = event.target.name;
    let value = removePriceStrFormatter(event.target.value);

    setUpdateObj((prev) => ({ ...prev, [name]: value }));
  };

  const handleUpdate = async () => {
    const { data } = await updateWage({
      variables: {
        ...updateObj,
      },
    });

    if (data?.editUserWage) {
      if (handleSnack) {
        handleSnack("Wage Updated!", 3000);
      }
      updateUserWageCB({...updateObj})
      closeWageModal()
      return;
    }

    if (handleSnack) {
      handleSnack("Something went wrong...", 3000);
    }
    closeWageModal()
  };

  return (
    <div className="list-users-modal-container" onClick={closeWageModal}>
      <div
        className="list-users-modal-content"
        onClick={(event) => event.stopPropagation()}
      >
        <div className="list-users-modal-hdr">
          <h4>
            {`Update ${selectedUser?.firstName} ${selectedUser?.lastName}'s Wage`}
          </h4>
          <button
            onClick={closeWageModal}
            className="list-users-close-modal-btn"
          >
            Close
          </button>
        </div>
        <div className="list-users-wage-input-box">
          <CssTextFieldStandard
            sx={{ width: "80%", my: 1 }}
            type="tel"
            label={`Wage`}
            inputProps={{ inputMode: "numeric" }}
            name={"wage"}
            value={priceStrFormatter(updateObj?.wage)}
            onChange={handleChange}
            variant="standard"
          />
          <button onClick={handleUpdate} className="list-users-close-modal-btn">
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default WageModal;
