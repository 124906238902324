import React, { useEffect, useState, useRef } from "react";
import "./imageGroups.css";
import AdminPictureUpload from "../../adminComponents/AdminPictureUpload/AdminPicUpload";
import FinestJobsController from "../../adminComponents/FinestJobsControl/FinestJobsController";
import DeleteBAGroup from "../../adminComponents/DeleteBagGroupDropdown/DeleteBagGroupComp";
import SinglePictureLookup from "../../adminComponents/SinglePictureLookup/SinglePictureLookup";
import RecentBAGsDropdown from "../../adminComponents/RecentBAGsDropdown/RecentBAGsDropdown";
import { Snackbar } from "@mui/material";

const AdminImageGroups = () => {
  const [newGroupId, setNewGroupId] = useState(false);
  const [totalMinHeight, setTotalMinHeight] = useState(2000);
  const [pageHeightTrigger, setPageHeightTrigger] = useState(0);
  const ImagePage = useRef();
  const [openSnack, setOpenSnack] = useState({
    visible: false,
    message: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    handlePageHeight();
  }, []);

  useEffect(() => {
    if (pageHeightTrigger > 0) {
      handlePageHeight();
    }
  }, [pageHeightTrigger]);

  const handleNewGroupId = (id) => {
    setNewGroupId(id);
  };

  const heightTrigger = () => {
    setPageHeightTrigger((prev) => prev + 1);
  };

  const handlePageHeight = () => {
    setTimeout(() => {
      const createBAGHeight = document.querySelector(
        "#admin-create-bag-height-marker"
      )?.offsetHeight;
      const fineHeight = document.querySelector(
        "#admin-fj-height-marker"
      )?.offsetHeight;
      const navHeight = document.querySelector(
        "#admin-nav-height-marker"
      )?.offsetHeight;
      const headerHeight =
        document.querySelector("#image-page-header")?.offsetHeight;
      const deleteBAGHeight = document.querySelector(
        "#delete-ba-group-height-marker"
      )?.offsetHeight;
      const photoLookup = document.querySelector(
        "#delete-ba-group-height-marker"
      )?.offsetHeight;

      const totalHeight =
        createBAGHeight +
        photoLookup +
        deleteBAGHeight +
        fineHeight +
        navHeight +
        headerHeight +
        window.innerHeight;
      setTotalMinHeight(totalHeight);
    }, 1000);
  };

  const handleSnack = (mes, ms) => {
    setOpenSnack({
      visible: true,
      message: mes,
      duration: ms,
    });
  };

  const handleSnackClose = () => {
    setOpenSnack({
      visible: false,
      message: "",
    });
  };

  return (
    <div
      ref={ImagePage}
      style={{ minHeight: `${totalMinHeight}px` }}
      className="admin-container"
    >
      <Snackbar
        open={openSnack.visible}
        autoHideDuration={openSnack.duration || 2000}
        onClose={handleSnackClose}
        message={openSnack.message}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <FinestJobsController
        heightTrigger={heightTrigger}
        newGroupId={newGroupId}
      />
      <AdminPictureUpload
        heightTrigger={heightTrigger}
        handleNewGroupId={handleNewGroupId}
        handleSnack={handleSnack}
      />
      <DeleteBAGroup heightTrigger={heightTrigger} />
      <SinglePictureLookup heightTrigger={heightTrigger} />
      <RecentBAGsDropdown heightTrigger={heightTrigger} />
    </div>
  );
};

export default AdminImageGroups;
