import React, { useEffect } from "react";
import "./contact.css";
import SocialIcons from "../../components/SocialIcons/SocialIcons";
import Tracking from "../../components/TrackingComp";
import { Helmet } from "react-helmet";
// import SpecialModal from "../../components/SpecialModal/SpecialModal";

const Contact = ({ displayHeader }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // window.document.title = "Contact Us";
  }, []);

  return (
    <div className="contact-page-container">
      <meta name="description" content="Business Hours: M-F 9am-5pm" />
      <Helmet>
        <title>Contact Us</title>
        data-nosnippet
      </Helmet>
      {/* <SpecialModal /> */}
      {displayHeader === true && (
        <h1 className="page-header" data-nosnippet>
          Contact Us!
        </h1>
      )}
      <div className="contact-info-box" data-nosnippet>
        <div className="social-icons">
          <SocialIcons />
        </div>
        <ul className="contact-ul">
          <li>
            <h2>Business Hours: M-F 9am-5pm</h2>
          </li>
          <li>
            <h3>Contact us during business hours by phone or text at:</h3>
            <h2>
              <a href="tel:12105897431">210-589-7431</a>
            </h2>
          </li>
          <li>
            <h3>Or send us an email anytime at :</h3>
            <h2 className="contact-links">
              <a href="mailto: leanne@bigginsdoorrefinishing.com">
                leanne@bigginsdoorrefinishing.com
              </a>
            </h2>
          </li>
        </ul>
        <h4 className="contact-thanks">We look forward to hearing from you!</h4>
      </div>
      <Tracking page={"contact"} data-nosnippet />
    </div>
  );
};

export default Contact;
