import React, { useEffect, useState } from "react";

import { QUERY_MORE_BA_GROUPS } from "../../utils/GraphQL/queries";
import { useLazyQuery } from "@apollo/client";

import "./ourDoors.css";
import RecentProjectsCarousel from "../../components/Carousels/OurDoorsCarousel/RecentProjectsCarousel";
import BdrSpinner from "../../components/BdrSpinner/BdrSpinner";
import FinestJobsTiles from "../../components/Carousels/FinestJobsTiles/FinestJobsTiles";
import Tracking from "../../components/TrackingComp";
import BAGroupTimeFormat from "../../utils/formatters/BAGroupTimeFormat";
import ImgGroupModal from "../../components/Modal/imgGroupModal";
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";
// import SpecialModal from "../../components/SpecialModal/SpecialModal";

const OurDoors = () => {
  const [getMoreBAGroups] = useLazyQuery(QUERY_MORE_BA_GROUPS);
  const [imageDataArr, setImageDataArr] = useState({});
  const [imagesLoading, setImagesLoading] = useState(false);
  const [imgModalOpen, setImgModalOpen] = useState(false);
  const [modalGroupArrId, setModalGroupArrId] = useState(Number);
  const [paginationState, setPaginationState] = useState({
    recents: false,
    finest: false,
    more: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // window.document.title = "Our Refinished Doors";
    pagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pagination = () => {
    if (paginationState.recents === false) {
      setPaginationState((prev) => ({ ...prev, recents: true }));
      return;
    }

    if (paginationState.finest === false) {
      setPaginationState((prev) => ({ ...prev, finest: true }));
      return;
    }

    if (paginationState.more === false) {
      setPaginationState((prev) => ({ ...prev, more: true }));
      setImagesLoading(true);
      queryCall();
      return;
    }
    return;
  };

  const queryCall = async () => {
    const local = Date.now().toString();
    const { data } = await getMoreBAGroups({
      variables: { localTimeEpoch: local },
    });

    if (data?.getMoreDoors) {
      setImageDataArr({ ...data });
      setImagesLoading(false);
    }
    return;
  };

  // modal controls
  const handleImgOpen = () => setImgModalOpen(true);
  const handleImgClose = () => setImgModalOpen(false);

  const imgModalEvent = (event) => {
    handleImgOpen();
    if (event.target.id === "") {
      setModalGroupArrId(event.target.parentNode.id);
      return;
    }

    setModalGroupArrId(event.target.id);
  };

  return (
    <div>
      <meta
        name="description"
        content="The gallery of our most recent projects!"
      />
      <Helmet data-nosnippet>
        <title>Our Refinished Doors</title>
      </Helmet>
      {imgModalOpen ? (
        <ImgGroupModal
          BAGroup={imageDataArr?.getMoreDoors[modalGroupArrId]}
          handleImgClose={handleImgClose}
          imgModalOpen={imgModalOpen}
          data-nosnippet
        />
      ) : null}
      {/* <SpecialModal /> */}
      <div className="our-doors-page-flex-container" data-nosnippet>
        <h2 className="page-header">Our Recent Projects!</h2>
      </div>
      <div className="our-doors-image-box" data-nosnippet>
        <RecentProjectsCarousel
          paginationState={paginationState.recents}
          paginationCB={pagination}
        />
        <div className="our-doors-page-flex-container"></div>
        <div className="our-doors-page-flex-container">
          <h2 className="page-header">Some of our finest work...</h2>
        </div>

        <div className="our-doors-page-flex-container">
          <FinestJobsTiles
            paginationState={paginationState.finest}
            paginationCB={pagination}
          />
        </div>

        <div className="page-header">
          <h2>And More!</h2>
          <h5 className="click-to-enlarge">(click images to enlarge)</h5>
        </div>
        <div className="more-images">
          {imagesLoading ? (
            <BdrSpinner message={"Loading..."} />
          ) : (
            imageDataArr?.getMoreDoors &&
            imageDataArr?.getMoreDoors.map((singleGroupData, index) => {
              return (
                <div
                  className="more-images-box"
                  key={singleGroupData?._id + index}
                >
                  <Button
                    className="more-images-img-box"
                    id={index}
                    onClick={imgModalEvent}
                  >
                    <img
                      className="more-images-img"
                      src={singleGroupData?.beforeId?.imageData}
                      alt={"Door Project Before" + (index + 1)}
                    />
                    <img
                      className="more-images-img"
                      src={singleGroupData?.afterId?.imageData}
                      alt={"Door Project After" + (index + 1)}
                    />
                  </Button>
                  <h4 className="more-images-stain-hdr">
                    {singleGroupData?.stainColor}
                  </h4>
                  <h4 className="more-images-stain-hdr">
                    {BAGroupTimeFormat(singleGroupData?.dateEntered)}
                  </h4>
                </div>
              );
            })
          )}
        </div>
      </div>
      <Tracking page={"ourDoors"} data-nosnippet/>
    </div>
  );
};

export default OurDoors;
