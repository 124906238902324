const priceStrFormatter = (price) => {
  if (price) {
    const filteredNumber = price?.toString()?.replace(/[^0-9]/gi, "");
    let number = parseInt(filteredNumber) / 100;
    if (isNaN(number)) {
      return "0";
    }

    let neg = /-/gi.test(price);

    if (number === 0) {
      neg = false;
    }

    number = number.toFixed(2);

    const decimal = number?.split(".")[1];
    const whole = number?.split(".")[0];
    const billions = whole?.slice(-12, -9);
    const millions = whole?.slice(-9, -6);
    const thousands = whole?.slice(-6, -3);
    const hundreds = whole?.slice(-3);

    // Format to thousands
    if (filteredNumber?.length >= 6 && filteredNumber?.length < 9) {
      number = `${thousands},${hundreds}.${decimal}`;
    }

    // Format to millions
    if (filteredNumber?.length >= 9 && filteredNumber?.length < 12) {
      number = `${millions},${thousands},${hundreds}.${decimal}`;
    }
    
    // Format to billions
    if (filteredNumber?.length >= 12 && filteredNumber?.length < 15) {
      number = `${billions},${millions},${thousands},${hundreds}.${decimal}`;
    }

    return `${neg ? "-" : ""}$${number}`;
  }
  return "0";
};

const removePriceStrFormatter = (price) => {
  if (price) {
    const filteredNumber = price.replace(/[^0-9]/gi, "");
    const number = parseInt(filteredNumber);
    if (isNaN(number)) {
      return "0";
    }

    let neg = /-/gi.test(price);

    if (number === 0) {
      neg = false;
    }

    return `${neg ? "-" : ""}${number}`;
  }

  return "0";
};

const quantityFormatter = (quantity) => {
  if (quantity.length) {
    const filteredNum = quantity.replace(/[^0-9]/gi, "");

    if (filteredNum) {
      let number = filteredNum;
      number = parseInt(filteredNum);

      let neg = /-/gi.test(quantity);

      if (number === 0) {
        neg = false;
      }

      return `${neg ? "-" : ""}${number}`;
    }

    return "0";
  }

  return "0";
};

export { priceStrFormatter, removePriceStrFormatter, quantityFormatter };
