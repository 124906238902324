import React, { useEffect, useState } from "react";
// import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import useMediaQuery from "@mui/material/useMediaQuery";

const radioStyle = {
  radioLabels: "#fff",
  radioCircles: "#000",
  radioCirclesChecked: "#05F842",
};

const QuoteRadioForm = (props) => {
  const {
    name,
    question,
    choices,
    choiceDefault,
    formId,
    choiceStateFunction,
    endComponent,
    mediaQueryWidth,
    forceRow,
    BoxCSS,
    passValue,
    resetForm
  } = props;

  const mediaQuery = useMediaQuery(mediaQueryWidth || "(min-width: 900px)");
  const [selectionState, setSelectionState] = useState(null);

  useEffect(() => {
    if (choiceDefault) {
      setSelectionState(choiceDefault)
    }
  }, [choiceDefault])
  
  useEffect(() => {
    if (resetForm && choiceDefault) {
      setSelectionState(choiceDefault)
    }
    
    if (resetForm && !choiceDefault) {
      setSelectionState(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetForm])

  return (
    <FormControl id={formId} className={BoxCSS ? BoxCSS : "radio-form"}>
      <FormLabel sx={{ color: "#fff", "&.Mui-focused": { color: "#fff" } }}>
        <span className="form-labels">{question}</span>
        <RadioGroup
          aria-labelledby={formId}
          defaultValue={choiceDefault}
          value={passValue ? passValue : selectionState}
          onChange={(event) => {
            choiceStateFunction(event);
            if (!passValue) {
              setSelectionState(event?.target?.value)
            }
          }}
          name={name}
          {...(mediaQuery
            ? { row: forceRow || true }
            : { row: forceRow || false })}
          className="center-box"
        >
          {choices.map((choice, index) => {
            return (
              <FormControlLabel
                key={choice + index}
                value={choice}
                sx={{ color: radioStyle.radioLabels }}
                control={
                  <Radio
                    sx={{
                      color: radioStyle.radioCircles,
                      "&.Mui-checked": {
                        color: radioStyle.radioCirclesChecked,
                      },
                    }}
                  />
                }
                label={choice}
              />
            );
          })}
        </RadioGroup>
        {endComponent ? endComponent : null}
      </FormLabel>
    </FormControl>
  );
};

export default QuoteRadioForm;
