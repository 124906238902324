import React, { useEffect, useState } from "react";
import BAGroupTimeFormat from "../../../../utils/formatters/BAGroupTimeFormat";
import AirDatepicker from "air-datepicker";
import "air-datepicker/air-datepicker.css";
import "./calendarTimeSelect.css";
import localeEn from "air-datepicker/locale/en";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const blankStartStop = {
  start: "",
  end: "",
};

const CalendarTimeSelect = ({
  returnMill,
  hrSelect,
  leadingText,
  justifyContent,
  passedSelectedTime,
  hideInput,
  passedColor,
  onlyFridays,
  openCal,
  closeCalStateCB,
  hideIcon,
  minDate,
  maxDate,
  handleSnack,
  passedFontSize,
  hidden,
  clearInput,
}) => {
  const [selectedTime, setSelectedTime] = useState("");
  const [startStopTime, setStartStopTime] = useState({ ...blankStartStop });
  const [attemptStartEndReturn, setAttemptStartEndReturn] = useState(false);

  useEffect(() => {
    if (passedSelectedTime) {
      setSelectedTime(passedSelectedTime);
    }

    if (clearInput) {
      setSelectedTime("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passedSelectedTime, clearInput]);

  useEffect(() => {
    if (attemptStartEndReturn) {
      const bothSelected =
        startStopTime?.start?.length > 0 && startStopTime?.end?.length > 0;

      if (bothSelected) {
        if (parseInt(startStopTime?.end) > parseInt(startStopTime?.start)) {
          returnMill({ ...startStopTime });
          return;
        }

        if (parseInt(startStopTime?.start) >= parseInt(startStopTime?.end)) {
          if (handleSnack) {
            handleSnack("End time must be greater then start time.", 4000);
          }
          return;
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attemptStartEndReturn]);

  useEffect(() => {
    if (openCal) {
      calInitAndSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openCal]);

  const clearState = () => {
    // setSelectedTime("");
    setStartStopTime({ ...blankStartStop });
    setAttemptStartEndReturn(false);
  };

  const calInitAndSelect = () => {
    let cal2 = null;
    const buttonArr = minDate
      ? [
          {
            content: "Save",
            className: "cal-time-sel-start-button",
            onClick: (date) => {
              setAttemptStartEndReturn(true);

              cal.hide();
              if (cal2) {
                cal2.hide();
              }

              return setTimeout(() => {
                cal.destroy();
                if (cal2) {
                  cal2.destroy();
                }
                clearState();
              }, 500);
            },
          },
          {
            content: "Cancel",
            className: "cal-time-sel-start-button",
            onClick: () => {
              cal.hide();
              if (cal2) {
                cal2.hide();
              }

              return setTimeout(() => {
                cal.destroy();
                if (cal2) {
                  cal2.destroy();
                }
                clearState();
              }, 500);
            },
          },
        ]
      : [
          {
            content: "Today",
            className: "cal-time-sel-start-button",
            onClick: () => {
              const now = Date.now().toString();
              returnMill(now);
              setSelectedTime(now);
              cal.hide();
              if (cal2) {
                cal2.hide();
              }

              return setTimeout(() => {
                cal.destroy();
                if (cal2) {
                  cal2.destroy();
                }
                clearState();
              }, 500);
            },
          },
        ];

    const returnMills = ({ date }) => {
      // Returns string milliseconds at midnight of that day.
      // Add hrs variable for time selection
      const selectedMill = parseInt(cal.formatDate(date, "T"));
      const hrSelection = hrSelect ? 1000 * 60 * 60 * hrSelect : 0;
      const totalMillStr = date
        ? (selectedMill + hrSelection).toString()
        : selectedTime
        ? selectedTime
        : (selectedMill + hrSelection).toString();

      returnMill(totalMillStr);
      setSelectedTime(totalMillStr);
      cal.hide();
      if (cal2) {
        cal2.hide();
      }

      return setTimeout(() => {
        cal.destroy();
        if (cal2) {
          cal2.destroy();
        }
        clearState();
      }, 500);
    };

    const selectTimes = ({ date }, calNum) => {
      // calNum is 0-2 Number type. 0 === both , 1 === cal, 2 === cal2.
      const selectedMill = parseInt(cal.formatDate(date, "T"));
      const calMills = parseInt(cal.formatDate(cal?.lastSelectedDate, "T"));
      const cal2Mills = parseInt(cal2.formatDate(cal2?.lastSelectedDate, "T"));

      if (calNum === 0 || calNum === 1) {
        cal.selectDate(selectedMill, { silent: true });
        const start = selectedMill?.toString();
        setStartStopTime((prev) => {
          return { ...prev, start };
        });
      }

      if (cal2) {
        if (calNum === 0 || calNum === 2) {
          cal2.selectDate(selectedMill, { silent: true });
          const end = selectedMill?.toString();
          setStartStopTime((prev) => {
            return { ...prev, end };
          });
        }
      }

      if (calMills >= cal2Mills || cal2Mills <= calMills) {
        cal.selectDate(selectedMill, { silent: true, updateTime: true });
        cal2.selectDate(selectedMill, { silent: true, updateTime: true });
        const stringNum = selectedMill.toString();
        setStartStopTime(() => {
          return { start: stringNum, end: stringNum };
        });
      }

      return;
    };

    const opt = minDate
      ? { minDate, maxDate, timepicker: true, minutesStep: 5, autoClose: false }
      : { timepicker: false, autoClose: false };

    if (minDate) {
      cal2 = new AirDatepicker("#cal-el-btn", {
        isMobile: true,
        ...opt,
        disableNavWhenOutOfRange: true,
        onlyTimepicker: true,
        // position: ({ $datepicker }) => {
        //   setPosition($datepicker, minDate ? "right" : "center");
        // },
        range: false,
        locale: localeEn,
        buttons: buttonArr,
        onHide: () => {
          if (closeCalStateCB) {
            closeCalStateCB(false);
          }
        },
        onRenderCell: ({ date, cellType }) => {
          if (onlyFridays) {
            if (cellType === "day") {
              const day = date?.getDay();

              if (day !== 5) {
                return {
                  disabled: true,
                  classes: "disabled-class",
                  attrs: {
                    title: "Cell is disabled",
                  },
                };
              }
            }
          }
        },
        onSelect: minDate
          ? (obj) => {
              selectTimes(obj, 2);
            }
          : returnMills,
        navTitles: {
          days: (date) => {
            return onlyFridays
              ? "Select Timesheet Start Date"
              : minDate
              ? "Select End Date"
              : `${date?.formatDate(date?.viewDate, "MMMM, <i>yyyy</i>")}`;
          },
        },
      });
    }

    const cal = new AirDatepicker("#cal-el-btn", {
      isMobile: true,
      ...opt,
      disableNavWhenOutOfRange: true,
      // position: ({ $datepicker }) => {

      // },
      range: false,
      locale: localeEn,
      buttons: minDate ? [] : buttonArr,
      dateFormat: (date) => {
        const dateStr = new Date(date).getTime().toString();
        return BAGroupTimeFormat(dateStr);
      },
      onHide: () => {
        if (closeCalStateCB) {
          closeCalStateCB(false);
        }
      },
      onRenderCell: ({ date, cellType }) => {
        if (onlyFridays) {
          if (cellType === "day") {
            const day = date?.getDay();

            if (day !== 5) {
              return {
                disabled: true,
                classes: "disabled-class",
                attrs: {
                  title: "Cell is disabled",
                },
              };
            }
          }
        }
      },
      onSelect: minDate
        ? (obj) => {
            selectTimes(obj, 1);
          }
        : returnMills,
      navTitles: {
        days: (date) => {
          return onlyFridays
            ? "Select Timesheet Start Date"
            : minDate
            ? "Select Start Date"
            : `${date?.formatDate(date?.viewDate, "MMMM, <i>yyyy</i>")}`;
        },
      },
    });

    const focusedDate = passedSelectedTime
      ? new Date(parseInt(passedSelectedTime))
      : new Date();

    const alreadySelected = selectedTime
      ? new Date(parseInt(selectedTime))
      : focusedDate;

    if (!onlyFridays) {
      cal.setFocusDate(alreadySelected, { viewDateTransition: true });
      cal.selectDate(alreadySelected, { silent: true });
    }

    if (onlyFridays) {
      cal.setFocusDate(alreadySelected, { viewDateTransition: true });
    }

    cal.show();
    if (cal2) {
      cal2.selectDate(alreadySelected, { silent: true });
      cal2.show();
    }

    // Position calendars
    const docWidth = window.innerWidth / 2;
    const docHeight = window.innerHeight / 2;
    const dpHeight = cal.$datepicker.clientHeight / 2;

    if (!cal2) {
      cal.$datepicker.style.top = `${docHeight}px`;
      cal.$datepicker.style.left = `${docWidth}px`;
    }

    if (cal2) {
      const dp2Height = cal2.$datepicker.clientHeight / 2;
      // Full Heights for margin calc
      const dp2FullHeight = cal2.$datepicker.clientHeight;
      const dpFullHeight = cal.$datepicker.clientHeight;
      const docFullHeight = window.innerHeight;
      const marginTotal = docFullHeight - dpFullHeight - dp2FullHeight - 10;
      const margin = Math.floor(marginTotal / 2);

      // top cal
      cal.$datepicker.style.top = `${dpHeight + margin}px`;
      cal.$datepicker.style.left = `${docWidth}px`;
      // bottom timepicker
      cal2.$datepicker.style.top = `${margin + dpFullHeight + 10 + dp2Height}px`;
      cal2.$datepicker.style.left = `${docWidth}px`;
    }
  };

  const containerStyle = hidden
    ? {
        justifyContent: justifyContent ? justifyContent : "flex-start",
        color: passedColor ? passedColor : "#fff",
        height: "0px",
      }
    : {
        justifyContent: justifyContent ? justifyContent : "flex-start",
        color: passedColor ? passedColor : "#fff",
      };

  return (
    <div className="cal-time-select-container" style={containerStyle}>
      {leadingText && leadingText}
      <input
        type={hideInput ? "hidden" : "text"}
        className="cal-el-input"
        value={selectedTime ? BAGroupTimeFormat(selectedTime) : ""}
        placeholder="Select Date"
        readOnly={true}
        id="cal-el-btn"
        onClick={calInitAndSelect}
        style={{ margin: leadingText ? "1px 0px 1px 12px" : "1px 0px" }}
      />

      <button
        disabled={!!hideIcon}
        className="cal-time-sel-img-button"
        onClick={calInitAndSelect}
      >
        {!hideIcon && (
          <CalendarMonthIcon
            sx={{
              color: passedColor ? passedColor : "#fff",
              fontSize: passedFontSize ? passedFontSize : "inherit",
            }}
            titleAccess="Select Date"
          />
        )}
      </button>
    </div>
  );
};

export default CalendarTimeSelect;
