import React, { useEffect, useState, useRef } from "react";
import "./adminPictureUpload.css";
import PictureUploadAdmin from "./PictureUploadAdminComp";
import { CREATE_BA_GROUP } from "../../../../utils/GraphQL/mutations";
import { useMutation } from "@apollo/client";
import QuoteRadioForm from "../../../../components/QuoteRadioForm/QuoteRadioForm";
import Collapse from "@mui/material/Collapse";
import Checkbox from "@mui/material/Checkbox";
import CssTextField from "../../../../components/CssTextFields/CssTextFieldOutline";
import { MyFormHelperText } from "../../../../components/CssTextFields/CssTextFieldStandard";
import PictureFormTest from "../../../../utils/formValidators/adminBAGFormCheck";
import { Button, FormControlLabel, FormGroup, FormLabel } from "@mui/material";
import PlusMinusCollapse from "../../../../components/PlusMinusCollapse/PlusMinusCollapse";
import { useMediaQuery } from "@mui/material";
import CalendarTimeSelect from "../CalendarTimeSelect/CalendarTimeSelect";
import ClientSearchInput from "../ClientSearchInput/ClientSearchInput";

const clearedClient = {
  firstName: "",
  lastName: "",
  _id: "",
};

const clearBAGForm = {
  beforeId: "",
  afterId: "",
  assignToClientId: null,
  weekGalleryDisplay: true,
  dateEntered: "",
  refinishers: [],
  stainColor: "",
};

const clearRefinishers = {
  kyle: null,
  taylor: null,
  goose: null,
  seamus: null,
  neil: null,
};

const AdminPictureUpload = ({ handleNewGroupId, heightTrigger, handleSnack }) => {
  const [loadingSaved, setLoadingSaved] = useState(false);
  const [uploadClearStateTrigger, setUploadClearStateTrigger] = useState(false);
  const [formResetTrigger, setFormResetTrigger] = useState(false);
  const [barCollapsed, setBarCollapsed] = useState(true);
  const [newSavedGroupId, setNewSavedGroupId] = useState("");
  const [stainColorChoice, setStainColorChoice] = useState(null);
  const [BAGEpoch, setBAGEpoch] = useState("");
  const smaller900 = useMediaQuery("(max-width:900px)");
  const adminPictureScrollRef = useRef(null);
  const [selectedClient, setSelectedClient] = useState({ ...clearedClient });
  const [BAGroupForm, setBAGroupForm] = useState({ ...clearBAGForm });
  const [refinisherState, setRefinisherState] = useState({
    ...clearRefinishers,
  });
  //for triggering error helper texts on inputs when they fail the checks
  const [errorState, setErrorState] = useState({
    otherStainInput: false,
  });
  // for creating new BAGroup
  const [createBAG, { loading: BAGCreateLoading }] =
    useMutation(CREATE_BA_GROUP);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    addRefinishersToForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refinisherState]);

  useEffect(() => {
    if (formResetTrigger) {
      setFormResetTrigger(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formResetTrigger]);

  useEffect(() => {
    setBAGroupForm((prevState) => ({
      ...prevState,
      stainColor: stainColorChoice,
      dateEntered: BAGEpoch,
    }));
  }, [stainColorChoice, BAGEpoch]);

  // form validation class for otherInput
  const uploadInputCheck = new PictureFormTest(BAGroupForm);

  // clears state
  const stateCleanup = () => {
    setBAGroupForm({ ...clearBAGForm });
    setRefinisherState({ ...clearRefinishers });
    setSelectedClient({ ...clearedClient });
    setStainColorChoice(null);
    setBAGEpoch("");
    setUploadClearStateTrigger(true);
    setFormResetTrigger(true);

    const div = document.getElementById("admin-create-bag-height-marker");
    div.scrollTop = 0;
  };

  // CB for PictureUploadAdmin to reset clear state trigger
  const pictureUploadClearStateTriggerReset = () => {
    setUploadClearStateTrigger(false);
  };

  // changes before id on state
  const changeBeforeId = (photoId) => {
    setBAGroupForm((prevState) => ({
      ...prevState,
      beforeId: photoId,
    }));
  };

  // changes after id on state
  const changeAfterId = (photoId) => {
    setBAGroupForm((prevState) => ({
      ...prevState,
      afterId: photoId,
    }));
  };

  // added loading state for fetch call
  const changeLoading = async (newState) => {
    setLoadingSaved(newState);
    return;
  };

  const handleCheckbox = (event) => {
    let checked = event.target.checked;
    let name = event.target.name;

    setRefinisherState((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleChange = async (event) => {
    let value = event.target.value;
    let name = event.target.name;

    if (name === "weekGalleryDisplay") {
      value === "true" ? (value = true) : (value = false);
    }

    if (name === "stainColorChoice") {
      setStainColorChoice(value);
    }

    if (name === "otherStainInput") {
      setBAGroupForm((prev) => ({
        ...prev,
        stainColor: value,
      }));

      return;
    }

    setBAGroupForm((prev) => ({
      ...prev,
      [event.target.name]: value,
    }));
  };

  const addRefinishersToForm = () => {
    const refinisherArr = [];
    if (refinisherState.neil === true) {
      refinisherArr.push("Neil");
    }
    if (refinisherState.taylor === true) {
      refinisherArr.push("Taylor");
    }
    if (refinisherState.kyle === true) {
      refinisherArr.push("Kyle");
    }
    if (refinisherState.goose === true) {
      refinisherArr.push("Goose");
    }
    if (refinisherState.seamus === true) {
      refinisherArr.push("Seamus");
    }

    setBAGroupForm((prev) => ({
      ...prev,
      refinishers: refinisherArr,
    }));
  };

  //meant for clearing error state and message when input is focused on
  const clearError = (event) => {
    setErrorState({
      ...errorState,
      [event.target.name]: false,
    });
  };

  //used for handling selected client to assign uploaded pictures to
  const assignPicsFunc = (selectedClient) => {
    console.log(selectedClient);
    setBAGroupForm((prevState) => ({
      ...prevState,
      assignToClientId: selectedClient?._id,
    }));

    setSelectedClient({
      firstName: selectedClient?.firstName || "",
      lastName: selectedClient?.lastName || "",
      _id: selectedClient?._id || "",
    });
  };

  //used for clearing selected client
  const clearSelectedClient = () => {
    setBAGroupForm((prevState) => ({
      ...prevState,
      assignToClientId: null,
    }));

    setSelectedClient({
      firstName: "",
      lastName: "",
      _id: "",
    });
  };

  // validation check handler for "other" option and dateText inputs
  const checkError = async (event) => {
    let value = event.target.value;

    //value has to be flipped because the methods return true for valid input
    if (event.target.name === "otherStainInput") {
      value = await uploadInputCheck.testOther();
    }

    setErrorState({
      ...errorState,
      [event.target.name]: !value,
    });

    return;
  };

  const timeSelected = (timeStr) => {
    setBAGEpoch(timeStr);
  };

  return (
    <div
      id="admin-create-bag-height-marker"
      className="picture-upload-container"
      ref={adminPictureScrollRef}
    >
      <button
        onClick={() => {
          setTimeout(() => {
            heightTrigger();
            if (smaller900 === true && barCollapsed === true) {
              const navHeight = document.querySelector(
                "#admin-nav-height-marker"
              )?.offsetHeight;
              const pagePos = adminPictureScrollRef.current.offsetTop;

              const totalMobileScroll = pagePos - navHeight - 5;
              window.scrollTo(0, totalMobileScroll);
            }

            if (smaller900 === false && barCollapsed === true) {
              adminPictureScrollRef.current.scrollIntoView({ block: "start" });
            }

            if (barCollapsed === false) {
              window.scrollTo({ top: 0 });
            }
          }, 500);
          setBarCollapsed((prev) => !prev);
        }}
        className="header-buttons-collapse-sticky"
      >
        <h2>Create B&A Group</h2>
        <PlusMinusCollapse plus={barCollapsed} />
      </button>
      <div className={barCollapsed ? "content-hide" : "content-show"}>
        {newSavedGroupId?.length > 0 && (
          <h3>New Group Id: {newSavedGroupId}</h3>
        )}
        <div className="picture-upload-box">
          <div className="picture-upload-div">
            <h3>Before</h3>
            <PictureUploadAdmin
              changePhotoIds={changeBeforeId}
              changeLoading={changeLoading}
              BoaProp={"before"}
              clearState={uploadClearStateTrigger}
              clearStateTriggerCB={pictureUploadClearStateTriggerReset}
            />
            <span className="pic-id-text">
              {BAGroupForm.beforeId && (
                <span className="ba-ids">Id: {BAGroupForm.beforeId}</span>
              )}
            </span>
          </div>
          <div className="picture-upload-div">
            <h3>After</h3>
            <PictureUploadAdmin
              changePhotoIds={changeAfterId}
              changeLoading={changeLoading}
              BoaProp={"after"}
              clearState={uploadClearStateTrigger}
              clearStateTriggerCB={pictureUploadClearStateTriggerReset}
            />
            <span className="pic-id-text">
              {BAGroupForm.afterId && (
                <span className="ba-ids">Id: {BAGroupForm.afterId}</span>
              )}
            </span>
          </div>
        </div>
        <div className="pic-input-calendar-box">
          <h3 className="form-labels">Select Date Completed</h3>
          <CalendarTimeSelect
            leadingText={""}
            returnMill={timeSelected}
            hrSelect={10}
            clearInput={formResetTrigger}
            justifyContent={"center"}
          />
        </div>
        <QuoteRadioForm
          classNames="pic-input-form"
          choiceStateFunction={handleChange}
          name="weekGalleryDisplay"
          question={"Display in week gallery?"}
          formId={"door-side-radios"}
          choiceDefault={"true"}
          choices={["true", "false"]}
          mediaQueryWidth={"(min-width: 100px)"}
        />
        <div className="pic-input-form checkbox-display">
          <FormGroup>
            <FormLabel>
              <h3 className="checkbox-labels">Which refinishers assisted?</h3>
            </FormLabel>
            <div className="checkbox-row">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!refinisherState?.kyle}
                    sx={{ "&.Mui-checked": { color: "#05F842" } }}
                    name="kyle"
                    onChange={handleCheckbox}
                  />
                }
                label="Kyle"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!refinisherState?.taylor}
                    sx={{ "&.Mui-checked": { color: "#05F842" } }}
                    name="taylor"
                    onChange={handleCheckbox}
                  />
                }
                label="Taylor"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!refinisherState?.neil}
                    sx={{ "&.Mui-checked": { color: "#05F842" } }}
                    name="neil"
                    onChange={handleCheckbox}
                  />
                }
                label="Neil"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!refinisherState?.goose}
                    sx={{ "&.Mui-checked": { color: "#05F842" } }}
                    name="goose"
                    onChange={handleCheckbox}
                  />
                }
                label="Goose"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!refinisherState?.seamus}
                    sx={{ "&.Mui-checked": { color: "#05F842" } }}
                    name="seamus"
                    onChange={handleCheckbox}
                  />
                }
                label="Seamus"
              />
            </div>
          </FormGroup>
        </div>
        <QuoteRadioForm
          choiceStateFunction={handleChange}
          name="stainColorChoice"
          question={"Selected stain color?"}
          formId={"discount-radio"}
          choiceDefault={null}
          resetForm={formResetTrigger}
          choices={[
            "Ipswich Pine",
            "Golden Oak",
            "Natural",
            "Provincial",
            "Gunstock",
            "Special Walnut",
            "Red Oak",
            "Dark Walnut",
            "Jacobean",
            "Red Mahogany",
            "Ebony",
            "True Black",
            "Other",
          ]}
          endComponent={
            <Collapse in={BAGroupForm.stainColorChoice === "Other"}>
              <CssTextField
                sx={{ width: "100%" }}
                focused
                label={"Other"}
                onChange={handleChange}
                onBlur={checkError}
                onFocus={clearError}
                name="otherStainInput"
              />
              <MyFormHelperText
                errorText={"Please enter a response."}
                formError={errorState.otherStainInput}
              />
            </Collapse>
          }
        />{" "}
        <div className="pic-input-calendar-box">
          <h3 className="form-labels">
            {BAGroupForm?.assignToClientId
              ? "Assigned To:"
              : "Assign Pictures to Client?"}
          </h3>
          {BAGroupForm?.assignToClientId === null && (
            <ClientSearchInput returnResults={assignPicsFunc} />
          )}
          {BAGroupForm?.assignToClientId && (
            <div className="picture-upload-assigned-client-box">
              <h4>
                {`${selectedClient?.firstName} ${selectedClient?.lastName}`?.trim()}
              </h4>
              <button
                className="picture-upload-btn"
                onClick={clearSelectedClient}
              >
                Clear
              </button>
              <div className="picture-upload-small-text">
                *Pictures will not assign till group is uploaded*
              </div>
            </div>
          )}
        </div>
        {BAGCreateLoading ? (
          <h3>Saving...</h3>
        ) : (
          <Button
            disabled={loadingSaved}
            onClick={async () => {
              if (await uploadInputCheck.uploadValid()) {
                createBAG({
                  variables: {
                    beforeId: BAGroupForm.beforeId,
                    afterId: BAGroupForm.afterId,
                    weekGalleryDisplay: BAGroupForm.weekGalleryDisplay,
                    dateEntered: BAGroupForm.dateEntered,
                    refinishers: BAGroupForm.refinishers,
                    stainColor: BAGroupForm.stainColor,
                    assignToClientId: BAGroupForm.assignToClientId,
                  },
                  onCompleted: (data) => {
                    handleNewGroupId(data?.createBAGroup._id);
                    setNewSavedGroupId(data?.createBAGroup._id);
                    if (handleSnack) {
                      handleSnack("New Image Group Created", 2500)
                    }
                    stateCleanup();
                  },
                });
              }
            }}
            id="admin-upload-button"
          >
            Upload Group
          </Button>
        )}
      </div>
    </div>
  );
};

export default AdminPictureUpload;
